var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"reservedSurgery"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticStyle:{"background-color":"#eeeeee","border-radius":"10px","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("From"))),_c('br'),_vm._v(" "+_vm._s(_vm.search.DateFrom)+" ")])]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticStyle:{"background-color":"#2753D9","color":"white","border-radius":"10px","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("To"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.search.DateTo)+" ")])])],1),_c('v-date-picker',{ref:"calendar",staticClass:"m-2",attrs:{"multiple":"true","locale":_vm.$vs.rtl ? 'ar' : 'en',"attributes":[  {
          bar: 'green',
          dates:
            this.$store.state.patientList.MonthPatientReservationSurgeries.map(
              function (ob) { return ob.SurgeryDateFrom; }
            ),
        } ],"is-range":""},on:{"change":_vm.changeDateFormat,"dayclick":_vm.changeDateFormat,"update:from-page":_vm.pageChange},model:{value:(_vm.search.range),callback:function ($$v) {_vm.$set(_vm.search, "range", $$v)},expression:"search.range"}})],1),_c('b-col',{attrs:{"cols":"12","md":"9"}},[(_vm.IsHospitalRole)?_c('h3',{staticStyle:{"color":"orange","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("HospitalConfirmNote"))+" ")]):_vm._e(),_c('div',{staticClass:"data-list-container",attrs:{"id":"data-list-list-view"}},[_c('vs-table',{ref:"table",attrs:{"data":_vm.reservedSurgeries},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('tbody',{key:indextr,attrs:{"data":tr}},[_c('vs-tr',{staticClass:"cursor-pointer hover:text-primary"},[_c('vs-td',[_c('div',[(
                      tr.HospitalSurgery &&
                      tr.HospitalSurgery.Surgery &&
                      (tr.StatusID == 1 || tr.StatusID == 2 || tr.StatusID == 7|| tr.StatusID == 8)
                    )?_c('u',{staticStyle:{"color":"#2753D9","cursor":"pointer","text-decoration":"underline"},on:{"click":function($event){return _vm.ViewSurgeryData(tr)}}},[_vm._v(_vm._s(tr.HospitalSurgery.Surgery.Name))]):_vm._e(),(
                      tr.HospitalSurgery &&
                      tr.HospitalSurgery.Surgery &&
                      tr.StatusID != 1 &&
                      tr.StatusID != 2 &&
                      tr.StatusID != 7 &&
                      tr.StatusID != 8
                    )?_c('p',[_vm._v(" "+_vm._s(tr.HospitalSurgery.Surgery.Name)+" ")]):_vm._e()])]),_c('vs-td',[(
                    tr.Patient &&
                    (tr.StatusID == 1 || tr.StatusID == 2 || tr.StatusID == 7|| tr.StatusID == 8)
                  )?_c('u',{staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.showPatientHistory(tr.PatientID)}}},[_vm._v(" "+_vm._s(tr.Patient.Name))]):_vm._e(),(
                    tr.Patient &&
                    tr.StatusID != 1 &&
                    tr.StatusID != 2 &&
                    tr.StatusID != 7 &&
                      tr.StatusID != 8
                  )?_c('p',[_vm._v(" "+_vm._s(tr.Patient.Name)+" ")]):_vm._e()]),_c('vs-td',[_c('p',{staticClass:"product-name font-medium truncate",attrs:{"type":"date"}},[_vm._v(" "+_vm._s(tr.SurgeryDateFrom.split("T")[0])+" ")])]),_c('vs-td',[_c('p',{staticClass:"product-name font-medium truncate"},[_vm._v(" "+_vm._s(tr.SurgeryDateTo.split("T")[0])+" ")])]),_c('vs-td',[(tr.SurgeryDate)?_c('p',[_vm._v(_vm._s(tr.SurgeryDate.split("T")[0]))]):_vm._e(),((_vm.IsHospitalRole && tr.Status.Name != 'Completed') &&  tr.Status.Name != 'Cancelled'  && (tr.Status.Name != 'Confirmed') )?_c('u',{staticStyle:{"text-decoration":"underline"},on:{"click":function($event){_vm.ShowChangeStatus = true;
    _vm.addItemModel = {
      PatientReservationSurgeryID: tr.ID,
      HospitalSurgeryID: tr.HospitalSurgeryID,
      PatientID: tr.PatientID,
      dateFrom: tr.SurgeryDateFrom,
      dateTo: tr.SurgeryDateTo,
      viewType: 2,
    };}}},[_vm._v(_vm._s(_vm.$t("SetDate")))]):_vm._e()]),(_vm.IsHospitalRole)?_c('vs-td',[(tr.Doctor)?_c('span',[_vm._v(_vm._s(tr.Doctor.Name))]):_c('span'),_c('br'),((tr.Status &&  tr.Status.Name != 'Completed') && (tr.Status.Name != 'Cancelled') && (tr.Status.Name != 'Confirmed'))?_c('u',{staticStyle:{"text-decoration":"underline"},on:{"click":function($event){_vm.ShowDoctor = true;
                    _vm.addItemModel = {
                      PatientReservationSurgeryID: tr.ID,
                      HospitalSurgeryID: tr.HospitalSurgeryID,
                      viewType: 1,
                    };}}},[_vm._v(_vm._s(_vm.$t("Set")))]):_vm._e()]):_vm._e(),(_vm.IsHospitalRole)?_c('vs-td',[(tr.Price)?_c('p',[_vm._v(_vm._s(tr.Price + " USD"))]):_c('span',[_c('br')]),((tr.Status &&  tr.Status.Name != 'Completed') && (tr.Status.Name != 'Cancelled') && (tr.Status.Name != 'Confirmed'))?_c('u',{staticStyle:{"text-decoration":"underline"},on:{"click":function($event){_vm.ShowDoctor = true;
                    _vm.addItemModel = {
                      PatientReservationSurgeryID: tr.ID,
                      HospitalSurgeryID: tr.HospitalSurgeryID,
                      Price: tr.Price,
                      DoctorID: tr.DoctorID,
                      viewType: 2,
                    };}}},[_vm._v(" "+_vm._s(_vm.$t("Change")))]):_vm._e()]):_vm._e(),_c('vs-td',[((tr.Status &&  tr.Status.Name == 'Cancelled')  || (tr.Status.Name == 'Completed'))?_c('p',[_vm._v(" "+_vm._s(tr.Status.Name)+" ")]):(tr.Status)?_c('u',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.ShowChangeStatus = true;
                    _vm.addItemModel = {
                      viewType: 1,
                      PatientReservationSurgeryID: tr.ID,
                      HospitalSurgeryID: tr.HospitalSurgeryID,
                      PatientID: tr.PatientID,
                      CurrentStatusID: tr.StatusID,
                      PaymentStatusID: tr.PaymentStatusID
                    };}}},[_vm._v(_vm._s(tr.Status.Name))]):_vm._e()]),_c('vs-td',[((tr.PaymentTypeID != 5  && tr.PaymentStatus != null) ||  tr.StatusID==3)?_c('p',[_vm._v(" "+_vm._s(_vm.PaymentStatus(tr))+" ")]):(tr.PaymentStatus != null)?_c('u',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.ShowChangeStatus = true;
                    _vm.addItemModel = {
                      viewType: 3,
                      ID: tr.ID,
                      StatusID: tr.StatusID,
                      SurgeryDateFrom: tr.SurgeryDateFrom,
                      SurgeryDateTo: tr.SurgeryDateTo,
                      PaymentStatusID: tr.PaymentStatusID,
                      CurrentStatusID: tr.PaymentStatusID,
                    };}}},[_vm._v(_vm._s(_vm.PaymentStatus(tr)))]):_vm._e()])],1)],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(_vm._s(_vm.$t("Surgery")))]),_c('vs-th',[_vm._v(_vm._s(_vm.$t("Patient")))]),_c('vs-th',[_vm._v(_vm._s(_vm.$t("DateFrom")))]),_c('vs-th',[_vm._v(_vm._s(_vm.$t("DateTo")))]),_c('vs-th',[_vm._v(_vm._s(_vm.$t("SurgeryDate")))]),(_vm.IsHospitalRole)?_c('vs-th',[_vm._v(_vm._s(_vm.$t("Doctor")))]):_vm._e(),(_vm.IsHospitalRole)?_c('vs-th',[_vm._v(_vm._s(_vm.$t("Price")))]):_vm._e(),_c('vs-th',[_vm._v(_vm._s(_vm.$t("Status")))]),_c('vs-th',[_vm._v(_vm._s(_vm.$t("PaymentStatus")))])],1)],2)],1)])],1),_c('vs-popup',{attrs:{"title":"","active":_vm.ShowChangeStatus},on:{"update:active":function($event){_vm.ShowChangeStatus=$event}}},[(_vm.ShowChangeStatus)?_c('SurgerySetPopup',{attrs:{"itemModel":_vm.addItemModel},on:{"closePop":function($event){_vm.ShowChangeStatus = false;
          _vm.searchReservedSurgery();},"SaveChange":function($event){return _vm.SaveChange();}}}):_vm._e()],1),_c('vs-popup',{attrs:{"title":"","active":_vm.ShowDoctor},on:{"update:active":function($event){_vm.ShowDoctor=$event}}},[(_vm.ShowDoctor)?_c('SurgerySetDoctorPopup',{attrs:{"itemModel":_vm.addItemModel},on:{"closePop":function($event){_vm.ShowDoctor = false;
          _vm.searchReservedSurgery();},"SaveChange":function($event){return _vm.setDoctorAndPriceSurgery();}}}):_vm._e()],1),_c('vs-popup',{attrs:{"title":"","active":_vm.ShowSuccessPopup},on:{"update:active":function($event){_vm.ShowSuccessPopup=$event}}},[(_vm.ShowSuccessPopup)?_c('SuccessPopup',{attrs:{"Message":_vm.$t('SuccsessMessage')},on:{"CloseSeccussPopup":_vm.CloseSeccussPopup}}):_vm._e()],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"type":"border","color":"danger"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }