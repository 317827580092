<template>
  <div id="reservedSurgery">
    <!-- back -->
   
    <b-row>
      <!-- search side -->
      <b-col cols="12" md="3">
        <b-row >
          <b-col cols="12" md="6">
            <p
            style="background-color: #eeeeee; border-radius: 10px;text-align: center;"
          >
            {{ $t("From") }}<br />
            {{ search.DateFrom }}
          </p>
          </b-col>
          <b-col cols="12" md="6">
          <p
            style="background-color: #2753D9; color: white; border-radius: 10px;text-align: center;"
          >
            {{ $t("To") }} <br />
            {{ search.DateTo }}
          </p>
        </b-col>
      </b-row>
        <v-date-picker
          v-model="search.range"
          multiple="true"
          :locale="$vs.rtl ? 'ar' : 'en'"
          :attributes="[  {
          bar: 'green',
          dates:
            this.$store.state.patientList.MonthPatientReservationSurgeries.map(
              (ob) =>  ob.SurgeryDateFrom
            ),
        },
      ]"
          is-range
          @change="changeDateFormat"
          @dayclick="changeDateFormat"
          class="m-2"
          ref="calendar"
          @update:from-page="pageChange"
        />
        <!-- <b-card
          v-if="IsHospitalRole"
          style="height: 30vh"
        >
          <vs-input
            v-model="search.SpecialityName"
            placeholder="search"
            name="search"
          />
          <div
            v-for="item in filterSpeciality"
            :key="item.ID"
            :class="item.IsSelected ? 'Active' : 'InActive'"
            class="shadow-md w-full mt-2"
          >
            <p class="m-5 p-3 vx-row">
              <vs-checkbox
                v-model="item.IsSelected"
                @input="SpecialityChecked(item)"
              ></vs-checkbox>
              {{ item.Specialty.Name }}
            </p>
          </div>
        </b-card> -->
      </b-col>
      <b-col cols="12" md="9">
        <h3
          v-if="IsHospitalRole"
          style="color: orange;text-align: center;"
        >
          {{ $t("HospitalConfirmNote") }}
        </h3>
          <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            :data="reservedSurgeries"
          >

            <template slot="thead">
              <vs-th >{{ $t("Surgery") }}</vs-th>
              <vs-th >{{ $t("Patient") }}</vs-th>
              <vs-th>{{ $t("DateFrom") }}</vs-th>
              <vs-th>{{ $t("DateTo") }}</vs-th>
              <vs-th>{{ $t("SurgeryDate") }}</vs-th>
              <vs-th v-if="IsHospitalRole">{{ $t("Doctor") }}</vs-th>
              <vs-th v-if="IsHospitalRole">{{ $t("Price") }}</vs-th>
              <vs-th>{{ $t("Status") }}</vs-th>
              <vs-th>{{ $t("PaymentStatus") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <div >
                  <u
                  @click="ViewSurgeryData(tr)"
                    v-if="
                      tr.HospitalSurgery &&
                      tr.HospitalSurgery.Surgery &&
                      (tr.StatusID == 1 || tr.StatusID == 2 || tr.StatusID == 7|| tr.StatusID == 8)
                    "
                    style="color: #2753D9; cursor: pointer;text-decoration: underline;"
                    >{{ tr.HospitalSurgery.Surgery.Name }}</u
                  >
                  <p
                    v-if="
                      tr.HospitalSurgery &&
                      tr.HospitalSurgery.Surgery &&
                      tr.StatusID != 1 &&
                      tr.StatusID != 2 &&
                      tr.StatusID != 7 &&
                      tr.StatusID != 8
                    "
                  >
                    {{ tr.HospitalSurgery.Surgery.Name }}
                  </p>
                </div>
                  </vs-td>
                  <vs-td>
                    <u
                    style="text-decoration: underline;"
                  v-if="
                    tr.Patient &&
                    (tr.StatusID == 1 || tr.StatusID == 2 || tr.StatusID == 7|| tr.StatusID == 8)
                  "
                  @click="showPatientHistory(tr.PatientID)"
                >
                  {{ tr.Patient.Name }}</u
                >
                <p
                  v-if="
                    tr.Patient &&
                    tr.StatusID != 1 &&
                    tr.StatusID != 2 &&
                    tr.StatusID != 7 &&
                      tr.StatusID != 8
                  "
                >
                  {{ tr.Patient.Name }}
                </p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium truncate" type="date">
                  {{ tr.SurgeryDateFrom.split("T")[0] }}
                </p>
                  </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                  {{ tr.SurgeryDateTo.split("T")[0] }}
                </p>
                  </vs-td>
                    <vs-td>
                      <p v-if="tr.SurgeryDate">{{ tr.SurgeryDate.split("T")[0] }}</p>

<u
style="text-decoration: underline;"
  @click="
    ShowChangeStatus = true;
    addItemModel = {
      PatientReservationSurgeryID: tr.ID,
      HospitalSurgeryID: tr.HospitalSurgeryID,
      PatientID: tr.PatientID,
      dateFrom: tr.SurgeryDateFrom,
      dateTo: tr.SurgeryDateTo,
      viewType: 2,
    };
  "
  v-if="(IsHospitalRole && tr.Status.Name != 'Completed') &&  tr.Status.Name != 'Cancelled'  && (tr.Status.Name != 'Confirmed') "
  >{{ $t("SetDate") }}</u
>
                  </vs-td>
                    <vs-td v-if="IsHospitalRole">
                <span v-if="tr.Doctor">{{ tr.Doctor.Name }}</span>
                <span v-else></span>
                <br />
                 <!-- <u
                 style="text-decoration: underline;"
                  @click="
                    ShowDoctor = true;
                    addItemModel = {
                      PatientReservationSurgeryID: tr.ID,
                      HospitalSurgeryID: tr.HospitalSurgeryID,
                      DoctorID: tr.DoctorID,
                      viewType: 1,
                    };
                  "
                  v-if="(tr.Status &&  tr.Status.Name != 'Completed') && (tr.Status.Name != 'Cancelled')  && (tr.Status.Name != 'Confirmed')"
                >
                  {{ $t("Change") }}</u
                > -->
                <u
                style="text-decoration: underline;"
                  @click="
                    ShowDoctor = true;
                    addItemModel = {
                      PatientReservationSurgeryID: tr.ID,
                      HospitalSurgeryID: tr.HospitalSurgeryID,
                      viewType: 1,
                    };
                  "
                  v-if="(tr.Status &&  tr.Status.Name != 'Completed') && (tr.Status.Name != 'Cancelled') && (tr.Status.Name != 'Confirmed')"
                  >{{ $t("Set") }}</u
                >
                   
                  </vs-td> 
                   <vs-td v-if="IsHospitalRole">
                <p v-if="tr.Price">{{ tr.Price + " USD" }}</p>
                <span v-else><br /></span>
                
                <u
                style="text-decoration: underline;"
                v-if="(tr.Status &&  tr.Status.Name != 'Completed') && (tr.Status.Name != 'Cancelled') && (tr.Status.Name != 'Confirmed')"
                  @click="
                    ShowDoctor = true;
                    addItemModel = {
                      PatientReservationSurgeryID: tr.ID,
                      HospitalSurgeryID: tr.HospitalSurgeryID,
                      Price: tr.Price,
                      DoctorID: tr.DoctorID,
                      viewType: 2,
                    };
                  "
                >
                  {{ $t("Change") }}</u
                >
                   
                  </vs-td>
                    <vs-td>
                      <p
                v-if="(tr.Status &&  tr.Status.Name == 'Cancelled')  || (tr.Status.Name == 'Completed')">
                  {{ tr.Status.Name }}
                </p>
                <u
                  style="cursor: pointer"
                  @click="
                    ShowChangeStatus = true;
                    addItemModel = {
                      viewType: 1,
                      PatientReservationSurgeryID: tr.ID,
                      HospitalSurgeryID: tr.HospitalSurgeryID,
                      PatientID: tr.PatientID,
                      CurrentStatusID: tr.StatusID,
                      PaymentStatusID: tr.PaymentStatusID
                    };
                  "
                  v-else-if="tr.Status"
                  >{{ tr.Status.Name }}</u
                >
                  </vs-td>
                    <vs-td>
                   <!-- payment type must be hospital bank transfer to can change status -->
                <p v-if="(tr.PaymentTypeID != 5  && tr.PaymentStatus != null) ||  tr.StatusID==3">
                  {{ PaymentStatus(tr) }}
                </p>
                <u
                  style="cursor: pointer"
                  @click="
                    ShowChangeStatus = true;
                    addItemModel = {
                      viewType: 3,
                      ID: tr.ID,
                      StatusID: tr.StatusID,
                      SurgeryDateFrom: tr.SurgeryDateFrom,
                      SurgeryDateTo: tr.SurgeryDateTo,
                      PaymentStatusID: tr.PaymentStatusID,
                      CurrentStatusID: tr.PaymentStatusID,
                    };
                  "
                  v-else-if="tr.PaymentStatus != null"
                  >{{ PaymentStatus(tr) }}</u
                >
                  </vs-td>
                 
                 </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
        
      </b-col>
    </b-row>
    <vs-popup title="" :active.sync="ShowChangeStatus">
      <SurgerySetPopup
        :itemModel="addItemModel"
        v-if="ShowChangeStatus"
        @closePop="
          ShowChangeStatus = false;
          searchReservedSurgery();
        "
        @SaveChange="
          SaveChange();

        "
      ></SurgerySetPopup>
    </vs-popup>

    <vs-popup title="" :active.sync="ShowDoctor">
      <SurgerySetDoctorPopup
        :itemModel="addItemModel"
        v-if="ShowDoctor"
        @closePop="
          ShowDoctor = false;
          searchReservedSurgery();
        "
        @SaveChange="
          setDoctorAndPriceSurgery();
        "
      ></SurgerySetDoctorPopup>
    </vs-popup>

    <vs-popup title="" :active.sync="ShowSuccessPopup">
      <SuccessPopup
        :Message="$t('SuccsessMessage')"
        @CloseSeccussPopup="CloseSeccussPopup"
        v-if="ShowSuccessPopup"
      ></SuccessPopup>
    </vs-popup>
    <b-button
    type="border" color="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="$router.go(-1)"
    >
      {{$t('back')}}
    </b-button>
  </div>
</template>

<script>
import moment from "moment";
import HospitalModule from "@/store/hospital/moduleHospital.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleStatus from "@/store/settings/status/moduleStatus.js";
import SurgerySetPopup from "./SurgerySetPopup.vue";
import SurgerySetDoctorPopup from "./SurgerySetDoctorPopup.vue";
import SuccessPopup from "@/views/pages/register/SuccessPopup.vue";
import modulePaymentStatus from "@/store/settings/paymentStatus/modulePaymentStatus.js";
import { BAvatar,BFormDatepicker, BButton, BCard, BCardHeader,BFormTextarea ,BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable } from 'bootstrap-vue'

export default {
  components: {
    SurgerySetPopup,
    SurgerySetDoctorPopup,
    SuccessPopup,
    BAvatar,BFormDatepicker, BButton, BCard, BCardHeader,BFormTextarea ,BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable
  },
  data() {
    return {
      EnglishLan: false,
      IsHospitalRole: false,
      ShowSuccessPopup: false,
      addItemModel: {},
      ShowChangeStatus: false,
      ShowDoctor: false,
      hospitalSpecialty: [],

      format: "MM/DD/yyyy",
    };
  },
  computed: {
      search() {
      return this.$store.state.HospitalList.search;
    },
    attrs() {
       debugger
    var sugeries= [
        {
          bar: "green",
          dates:
            this.$store.state.patientList.MonthPatientReservationSurgeries.map(
              (ob) =>  ob.SurgeryDateFrom
            ),
        },
      ];

      var surgeriesDate=  this.$store.state.patientList.MonthPatientReservationSurgeries.filter(obj=> obj.SurgeryDate).map(
              (ob) =>  ob.SurgeryDate
        );
    if(surgeriesDate&&surgeriesDate.length>0)
        sugeries[0].dates.push(...surgeriesDate)
      return sugeries;
    },
    status() {
      return this.$store.state.StatusList.status;
    },
    reservedSurgeries() {
      return this.$store.state.patientList.PatientReservationSurgeries;
    },
    filterSpeciality() {
      if (this.search.SpecialityName) {
        var vm = this;
        return this.hospitalSpecialty.filter((obj) =>
          obj.Specialty.Name.toLowerCase().includes(
            vm.search.SpecialityName.toLowerCase()
          )
        );
      } else return this.hospitalSpecialty;
    },
  },
  methods: {
    PaymentStatus(tr) {
      debugger;
      if (tr.PaymentStatus.ID == 7)
        return !this.$vs.rtl ? "Pending" : "قيد الانتظار";
      else return tr.PaymentStatus.Name;
    },
    CloseSeccussPopup() {
      this.ShowSuccessPopup = false;
    },
    pageChange(obj) {
      debugger;
      var search = {
        DateFrom: obj.year + "/" + obj.month + "/1",
        DateTo:
          obj.year +
          "/" +
          obj.month +
          "/" +
          new Date(obj.year, obj.month, 0).getDate(),
      };
      if (this.IsHospitalRole)
        search.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;

     if (this.$acl.check('hospitalgroup'))
          search.HospitalGroupID = this.$store.state.AppActiveUser.HospitalGroupID;

      else search.DoctorID = this.$store.state.AppActiveUser.Doctor.ID;

      search.NotIncludePaymentStatusIds = [1];
      this.$store.dispatch(
        "patientList/getPatientReservationSurgeryCalender",
        search
      );
    },

    setDoctorAndPriceSurgery() {
      debugger
      this.$vs.loading();
      if (this.addItemModel.viewType == 1) {
        this.$store
          .dispatch(
            "HospitalList/SetDoctorPatientReservationSurgery",
            this.addItemModel
          )
          .then((res) => {
            this.$vs.loading.close();

            this.$vs.notify({
              title: this.$t("success"),
              text: res.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.ShowDoctor = false;
          this.ShowSuccessPopup = true;
            this.searchReservedSurgery();
          })
          .catch((err) => {
            this.$vs.loading.close();

            this.$vs.notify({
              title: this.$t("Error"),
              text: err.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
            this.ShowDoctor = false;
          });
      } else {
        debugger;
        this.$store
          .dispatch(
            "HospitalList/ChangePatientReservationSurgeryPice",
            this.addItemModel
          )
          .then((res) => {
            this.$vs.loading.close();

            this.$vs.notify({
              title: this.$t("success"),
              text: res.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.ShowDoctor = false;
             this.ShowSuccessPopup = true;
            this.searchReservedSurgery();
          })
          .catch((err) => {
            this.$vs.loading.close();

            this.$vs.notify({
              title: this.$t("Error"),
              text: err.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
            this.ShowDoctor = false;
          });
      }
    },
    SaveChange() {
      debugger
      this.$vs.loading();
        if (this.addItemModel.viewType == 1) {
          this.$store
            .dispatch(
              "HospitalList/ChangePatientReservationSurgeryStatus",
              this.addItemModel
            )
            .then((res) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: this.$t("success"),
                text: res.data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.ShowChangeStatus = false;
              this.ShowSuccessPopup = true;
              this.searchReservedSurgery();
            })
            .catch((err) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: this.$t("Error"),
                text: err.data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });

            });
        } else if (this.addItemModel.viewType == 3) {
          this.$store
            .dispatch(
              "HospitalList/UpdatePatientReservationSurgery",
              this.addItemModel
            )
            .then((res) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: this.$t("success"),
                text: res.data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.ShowChangeStatus = false;
              this.ShowSuccessPopup = true;
              this.searchReservedSurgery();
            })
            .catch((err) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: this.$t("Error"),
                text: err.data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });

            });
        } else {
          this.$store
            .dispatch(
              "HospitalList/ConfirmPatientReservationSurgeryDate",
              this.addItemModel
            )
            .then((res) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: this.$t("success"),
                text: res.data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.ShowChangeStatus = false;
              this.ShowSuccessPopup = true;
              this.searchReservedSurgery();
            })
            .catch((err) => {
              debugger;
              this.$vs.loading.close();
              this.$vs.notify({
                title: this.$t("Error"),
                text: err.data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });

            });
        }
    },
    SpecialityChecked(surgery) {
      this.searchReservedSurgery();
    },
    searchReservedSurgery() {
      if (this.IsHospitalRole||this.$acl.check('hospitalgroup')) {
        this.search.SpecialtyIds = this.hospitalSpecialty
          .filter((obj) => obj.IsSelected)
          .map((b) => b.Specialty.ID);
      }
      this.search.NotIncludePaymentStatusIds = [1];

      this.$store.dispatch(
        "patientList/getPatientReservationSurgery",
        this.search
      );
    },
    showPatientHistory(PatientID) {
      debugger;
      this.$router.push({
        name: "PatientMedicalHistorySession",
        params: { ID: PatientID },
      });
    },

    changeDateFormat() {
      debugger;
      this.search.DateFrom = moment(this.search.range.start).format(
        this.format
      );
      this.search.DateTo = moment(this.search.range.end).format(this.format);

      this.searchReservedSurgery();
    },
    ViewSurgeryData(tr) {
      debugger
      if (tr.StatusID == 1 || tr.StatusID == 2 || tr.StatusID == 7||tr.StatusID==8)
        this.$router.push(
          "/TreatmentsProfile/" + tr.HospitalSurgeryID + "/" + 0 + "/" + tr.ID
        );
    },
    back() {
      this.$router.go(-1);
    },
  },
  created() {
    if (!modulePaymentStatus.isRegistered) {
      this.$store.registerModule("PaymentStatusList", modulePaymentStatus);
      modulePaymentStatus.isRegistered = true;
    }

    this.$store.dispatch("PaymentStatusList/GetAllPaymentStatus");
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;
    this.IsHospitalRole = this.$acl.check("hospital");
    if (!HospitalModule.isRegistered) {
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    if (!moduleStatus.isRegistered) {
      this.$store.registerModule("StatusList", moduleStatus);
      moduleStatus.isRegistered = true;
    }

    this.$store.dispatch("StatusList/GetAllStatus");

    debugger;
    if(!this.search.range.start)
    {
        this.search.range.start = new Date();
        this.search.range.end = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 10
        );
    }
    if (this.IsHospitalRole)
      this.search.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;

     if (this.$acl.check('hospitalgroup'))
       this.search.HospitalGroupID = this.$store.state.AppActiveUser.HospitalGroupID;

    else
       this.search.DoctorID = this.$store.state.AppActiveUser.Doctor.ID;
    this.changeDateFormat();
  },
};
</script>

<style lang="scss">


#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
.vs-sidebar.vs-sidebar-parent {
  max-height: 1500px !important;
}
</style>