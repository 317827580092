<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="MyForm">
    <div slot="no-body">
      <div class="justify-center items-center customBG">
        <div class="d-theme-dark-bg" style="border-radius: 15px">
          <div class="p-5">
            

            <vs-input
              v-if="itemModel.viewType == 2"
              class="w-full"
              data-vv-validate-on="blur"
              name="Price"
              icon-pack="feather"
              v-model="itemModel.Price"
              :icon-after="true"
              :label="$t('Price')+' USD'"
            />
            <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-if="itemModel.viewType == 1"
              class="w-full mt-2 "
              label="Name"
              v-model="itemModel.DoctorID"
              :options="Doctors"
              :reduce="(ID) => ID.ID"
              placeholder="Doctor"
            />


            <div style="text-align: center">
              <b-button
                style="width: 60%; margin-top: 2rem"
                @click="$emit('SaveChange')"
                :disabled="(itemModel.viewType == 2&&!itemModel.Price)||(itemModel.viewType == 1&&!itemModel.DoctorID)"
                >{{ $t("Save") }}</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar,BFormDatepicker, BButton, BCard, BCardHeader,BFormTextarea ,BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable } from 'bootstrap-vue'

export default {
  data() {
    return {
          minDate:Date.now(),
    };
  },
  props: {
    itemModel: {
      type: Object,
      default: () => {},
    },
  },
  components: {BAvatar,BFormDatepicker, BButton, BCard, BCardHeader,BFormTextarea ,BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable},
  computed: {
   Doctors() {
      return this.$store.state.HospitalList.Doctors;
    },
  },
  created() {
    if(this.itemModel.viewType==1)
    {
    debugger;
    this.$store
      .dispatch("HospitalList/GetSurgeryDoctors",
       this.itemModel.HospitalSurgeryID
      );
    }
  },
};
</script>

<style lang="scss">
.MyForm {
  width: 50vh;
  margin: auto;
}
</style>
